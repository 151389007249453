import { faFacebook, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { FooterContainer } from '../../styles/Header'
import logo from "../../mainlogowhitesmall.png";

export default function Footer() {
  return (
    <FooterContainer>
      <div className="copyright">
        <img src={logo} height={50} style={{ marginBottom: 10 }} />
        <p className="slogan">Rampage Bölge Bayi</p>
        <span
          className="slogan"
          style={{
            color: "#fff",
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          Osmanağa Mah. Başçavuş sok. No: 210
          <span>
            Emintaş İş Merkezi Kadıköy/İstanbul
          </span>
        </span>
        <p className="icons">
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://www.facebook.com/halilibrahim.ciftci.946/",
                "_blank"
              )
            }
          />
          <FontAwesomeIcon
            icon={faWhatsapp}
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=+905551068206",
                "_blank"
              )
            }
          />
        </p>
      </div>
      <script src="https://asset.deha.dev/dehasoft.js"></script>
      <div
        style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}
        id="dehasoft-copyright"
      >
        <a
          href="https://dehasoft.com.tr"
          style={{ display: "flex", flexDirection: "column", width: "100%", paddingBottom: 8, justifyContent: "center", alignItems: "center" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://dehasoft.com.tr/dehasoftblack.png"
            style={{ height: "25px", width: "unset" }}
            alt="DehaSoft"
          />
        </a>
      </div>
    </FooterContainer>
  );
}
